import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import { fontWeights } from "../styles/fonts"
import textStyles from "../styles/textStyles"
import { duration } from "../styles/animations"

// Components
import { Row, Col } from "../components/layout/Grid"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "../components/animation/AnimatedTextScrollWrapper"
import AnimatedBackground from "../components/animation/AnimatedBackground"

// Animation file
import { default as animationData } from "../../static/lottieAnimations/tryUsHeroArrows.json"

// Types
export interface IProps {
  heroTitle: string
}

const TryUsHero = ({ heroTitle }: IProps) => {
  const [animationPaused, setAnimationPaused] = useState<boolean>(true)
  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid meet",
    },
  }

  useEffect(() => {
    const timeout = setTimeout(
      () => setAnimationPaused(false),
      duration.pagePatternTransition
    )

    return () => clearTimeout(timeout)
  }, [])

  return (
    <Container>
      <AnimatedScrollWrapper>
        <AnimatedBackground
          options={animationOptions}
          isPaused={animationPaused}
        >
          <HeroTitleRow>
            <Col xxs={6} xxsOffset={1} m={8} mOffset={4} l={6} lOffset={5}>
              {heroTitle && (
                <AnimatedTextScrollWrapper
                  text={[heroTitle]}
                  textStyles={titleStyle}
                />
              )}
            </Col>
          </HeroTitleRow>
        </AnimatedBackground>
      </AnimatedScrollWrapper>
    </Container>
  )
}

// Main styles
const titleStyle = css`
  ${mq.to.M`
    font-size: 24px;
  `}
  ${textStyles.headingS}
  font-weight: ${fontWeights.medium};
`

const Container = styled.div`
  color: ${colors.black};
  width: 100%;
  overflow: hidden;

  /* to make padding top from layout disappear */
  margin-top: -12.5vw;
  margin-top: calc(-1 * var(--base-column-size));

  ${mq.from.M`
    margin-top: -12.5vw;
    margin-top: calc(-1 * var(--base-column-size));
  `}
`

const HeroTitleRow = styled(Row)`
  text-align: center;

  ${mq.from.S`
    /* duplicate style because of IE */
    margin-bottom: 6.25vw;
    margin-bottom: var(--base-column-size);
  `}
`

export default TryUsHero
