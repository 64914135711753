import React from "react"
import Lottie from "react-lottie"
import styled from "styled-components"

// Styling
import { mq } from "../../styles/utils/mq"

// Types
interface IOptions {
  loop?: boolean
  autoplay?: boolean
  animationData: any
  rendererSettings: any
}

type EventName =
  | "complete"
  | "loopComplete"
  | "enterFrame"
  | "segmentStart"
  | "config_ready"
  | "data_ready"
  | "loaded_images"
  | "DOMLoaded"
  | "destroy"

interface ILottieEventListener {
  eventName: EventName
  callback: () => void
}

interface ILottieProps {
  options: IOptions
  width?: number
  height?: number
  eventListeners?: ILottieEventListener[]
  isPaused?: boolean
}

interface IProps extends ILottieProps {
  className?: string
}

const AnimatedBackground: React.FC<IProps> = ({
  options,
  width,
  height,
  eventListeners,
  isPaused,
  className,
  children,
}) => (
  <Container className={className}>
    <AnimationWrapper>
      <Lottie
        options={options}
        width={width}
        height={height}
        eventListeners={eventListeners}
        isPaused={isPaused}
      />
    </AnimationWrapper>
    <ContentWrapper>{children}</ContentWrapper>
  </Container>
)

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 450px;
  height: 245px;
  margin-left: calc((100vw - 450px) / 2);
  margin-top: -12px;

  ${mq.from.XS`
    width: 600px;
    height: 344px;
    margin-left: calc((100vw - 600px) / 2);
  `}

  ${mq.from.S`
    width: 768px;
    height: 438px;
    margin-left: calc((100vw - 768px) / 2);
  `}

  ${mq.from.M`
    width: 100%;
    height: calc((100vw * 0.58) - 24px);
    margin-top: -24px;
    margin-left: 0;
  `}

  ${mq.from.XL`
    width: 110vw;
    height: calc((110vw * 0.58) - 80px);
    margin-top: -80px;
    margin-left: calc(10vw / -2);
  `}

  ${mq.from.XXL`
    height: calc((110vw * 0.58) - 160px);
  `}
`

const AnimationWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    height: 1000px;
  }
`

const ContentWrapper = styled.div`
  width: 100vw;
`

export default AnimatedBackground
