import React, { useContext, useEffect } from "react"
import styled from "styled-components"

// Types
import { ISlice } from "../types/prismic/TryUs/"
import { IProps as IHero } from "../components/TryUsHero"

// Styling
import colors from "../styles/colors"
import { mq } from "../styles/utils/mq"

// Utils
import PageContext from "../utils/context/PageContext"

// Components
import SEO from "../components/layout/SEO"
import TryUsHero from "../components/TryUsHero"
import DiveIn from "../components/DiveIn"
import Testimonials from "../components/Testimonials"
import TryUsFormComponent from "../components/TryUsFormComponent"

// Config
import { config as defaultPageTransitionsConfig } from "../components/pageTransitions/config"

interface IData extends IHero {
  metaTitle?: string
  metaDescription?: string
}

interface IProps {
  data: IData
  slices: ISlice[]
}

// Setup
const pageTransitionsConfig = {
  ...defaultPageTransitionsConfig,
  particle: {
    ...defaultPageTransitionsConfig.particle,
    fillStyle: colors.accent1,
  },
}

// Utils
const renderSlice = (slice: ISlice) => {
  switch (slice.type) {
    case "dive_in":
      return (
        <DiveInWrapper>
          <DiveIn {...slice.data} />
        </DiveInWrapper>
      )
    case "testimonials__video":
      return <Testimonials {...slice.data} />
    case "contact_form1":
      return (
        <FormWrapper>
          <TryUsFormComponent {...slice.data} />
        </FormWrapper>
      )
    default:
      return null
  }
}

const TryUsPage = React.memo(({ data, slices }: IProps) => {
  const page = useContext(PageContext)

  useEffect(() => {
    page.updateColor(colors.accent2)
    page.updateTransition(pageTransitionsConfig)
  }, [])

  return (
    <>
      <SEO title={data.metaTitle} description={data.metaDescription} />

      <TryUsHero {...data} />

      {slices &&
        slices.length > 0 &&
        slices.map((slice: ISlice) => (
          <div key={slice.id}>{renderSlice(slice)}</div>
        ))}
    </>
  )
})

const DiveInWrapper = styled.section`
  margin-bottom: var(--base-column-size);

  ${mq.from.M`
    margin-bottom: 0;
  `}
`

const FormWrapper = styled.section`
  /* duplicate style because of IE */
  margin-bottom: 12.5vw;
  margin-bottom: calc(var(--base-column-size) * 2);
`

export default TryUsPage
