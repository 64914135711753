import React from "react"
import { graphql } from "gatsby"

// Types
import { ISliceType } from "../types/prismic/TryUs/"

// Components
import TryUsPage from "../pageViews/tryUsPageView"

// Utils
const formatTryUsPagePrismicData = (slice: any) => {
  switch (slice.type as ISliceType) {
    case "dive_in":
      return {
        id: slice.id,
        type: slice.type,
        data: {
          title: slice.primary.dive_in_title.text,
          text: slice.primary.text,
          image: {
            url: slice.primary.image.localFile.childImageSharp.fluid,
            alt: slice.primary.image.alt,
          },
        },
      }
    case "contact_form1":
      return {
        id: slice.id,
        type: slice.type,
        data: {
          title: slice.primary.title.text,
          text: slice.primary.text,
          buttonLabel: slice.primary.button_label,
          success: {
            title: slice.primary.success_state_title.text,
            text: slice.primary.success_state_text,
            image: {
              url:
                slice.primary.success_state_image.localFile.childImageSharp
                  .fluid,
              alt: slice.primary.success_state_image.alt,
            },
          },
          error: {
            title: slice.primary.error_state_title.text,
            text: slice.primary.error_state_text,
            image: {
              url:
                slice.primary.error_state_image.localFile.childImageSharp.fluid,
              alt: slice.primary.error_state_image.alt,
            },
          },
        },
      }
    case "testimonials__video":
      const formattedTestimonialItems = slice.items.map((item: any) => {
        return {
          name: item.name,
          role: item.role,
          quote: item.quote,
          poster: {
            url: item.poster.localFile.childImageSharp.fluid,
            alt: item.poster.alt,
          },
          email: item.email,
        }
      })

      return {
        type: slice.type,
        id: slice.id,
        data: {
          title: slice.primary.section_title.text,
          text: {
            html: slice.primary.section_text.html,
          },
          showBackgroundArrows: slice.primary.background_arrows,
          items: formattedTestimonialItems,
        },
      }
    default:
      return null
  }
}

const TryUsPageContainer = ({ data }: any) => {
  if (!data.tryUsPage) {
    return null
  }

  const tryUsRawData = data.tryUsPage.data

  const formattedData = {
    metaTitle: tryUsRawData.metaTitle,
    metaDescription: tryUsRawData.metaDescription,
    heroTitle: tryUsRawData.hero_title.text,
  }

  const slices = tryUsRawData.slices
    .filter((slice: any) => slice.type && slice)
    .map((slice: any) => formatTryUsPagePrismicData(slice))

  return <TryUsPage data={formattedData} slices={slices} />
}

export const query = graphql`
  query GetTryUsPage {
    tryUsPage: prismicTryUsPage {
      data {
        metaTitle: meta_title
        metaDescription: meta_description
        hero_title {
          text
        }
        slices: body {
          ... on PrismicTryUsPageBodyDiveIn {
            type: slice_type
            id
            primary {
              dive_in_title {
                text
              }
              text: dive_in_text {
                html
              }
              image: dive_in_image {
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                alt
              }
            }
          }
          ... on PrismicTryUsPageBodyContactForm1 {
            id
            type: slice_type
            primary {
              title {
                text
              }
              button_label
              success_state_title {
                text
              }
              success_state_text {
                html
              }
              success_state_image {
                alt
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              error_state_title {
                text
              }
              error_state_text {
                html
              }
              error_state_image {
                alt
                localFile {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TryUsPageContainer
