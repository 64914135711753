import React, { useState } from "react"
import styled from "styled-components"
import Image from "gatsby-background-image"

// Styles
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import { mq } from "../styles/utils/mq"

// Component
import TryUsForm from "./forms/TryUsForm"
import { Row, Col } from "./layout/Grid"
import AnimatedTextScrollWrapper from "../components/animation/AnimatedTextScrollWrapper"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"

// Types
import { IContactForm } from "../types/prismic/TryUs/ContactForm"

const TryUsFormComponent = ({
  title,
  text,
  buttonLabel,
  success,
  error,
}: IContactForm) => {
  /**
   * @TODO
   * Create a loading (submitting) state
   */
  const [successResponse, setSuccessResponse] = useState<boolean>(false)
  const [errorResponse, setErrorResponse] = useState<boolean>(false)

  return (
    <Col mOffset={2} m={14}>
      <Container>
        <Col xxsOffset={1} xxs={6} mOffset={1} m={14}>
          {!errorResponse && !successResponse && (
            <TryUsForm
              title={title}
              text={text}
              buttonLabel={buttonLabel}
              setSuccess={setSuccessResponse}
              setError={setErrorResponse}
            />
          )}
          {successResponse && (
            <StyledRow>
              <TextWrapper m={9}>
                <Title>
                  <AnimatedTextScrollWrapper
                    text={[success.title]}
                    textStyles={textStyles.headingM}
                  />
                </Title>
                <AnimatedScrollWrapper>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: success.text.html,
                    }}
                  />
                </AnimatedScrollWrapper>
              </TextWrapper>
              <Col mOffset={1} m={6}>
                <BackgroundImage
                  fluid={success.image.url}
                  alt={success.image.alt}
                />
              </Col>
            </StyledRow>
          )}
          {errorResponse && (
            <StyledRow>
              <TextWrapper m={9}>
                <Title>
                  <AnimatedTextScrollWrapper
                    text={[error.title]}
                    textStyles={textStyles.headingM}
                  />
                </Title>
                <AnimatedScrollWrapper>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: error.text.html,
                    }}
                  />
                </AnimatedScrollWrapper>
              </TextWrapper>
              <Col mOffset={1} m={6}>
                <BackgroundImage
                  fluid={error.image.url}
                  alt={error.image.alt}
                />
              </Col>
            </StyledRow>
          )}
        </Col>
      </Container>
    </Col>
  )
}

const Container = styled.section`
  padding: 40px 0;
  color: ${colors.white};
  background-color: ${colors.black};

  ${mq.from.M`
    padding: 80px 0;
  `}
`

const StyledRow = styled(Row)`
  align-items: center;
`

const Title = styled.div`
  margin-bottom: 12px;

  ${mq.from.L`
    margin-bottom: 18px;
  `}

  ${mq.from.XL`
    margin-bottom: 24px;
  `}
`

const Text = styled.div`
  > p:last-child {
    margin-bottom: 0;
  }
`

const TextWrapper = styled(Col)`
  ${mq.to.M`
    margin-bottom: 24px;
  `}
`

const BackgroundImage = styled(Image)`
  padding-top: 66.66666%;

  ${mq.from.M`
    padding-top: 100%;
  `}
`

export default TryUsFormComponent
