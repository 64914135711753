import React from "react"
import styled from "styled-components"
import { Formik, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"

// Styling
import { mq } from "../../styles/utils/mq"
import textStyles from "../../styles/textStyles"

// Components
import TextField from "./elements/TextField"
import Link from "../generic/Link"
import LoadingAnimaton from "../LoadingAnimation"
import AnimatedTextScrollWrapper from "../animation/AnimatedTextScrollWrapper"
import AnimatedScrollWrapper from "../animation/AnimatedScrollWrapper"
import { Col } from "../layout/Grid"

interface IProps {
  title: string
  text: {
    html: string
  }
  buttonLabel: string
  setSuccess: (arg: boolean) => void
  setError: (arg: boolean) => void
}

interface IFormValues {
  name: string
  company: string
  email: string
  phone: string
  challenge: string
}

const TryUsForm = ({
  title,
  text,
  buttonLabel,
  setSuccess,
  setError,
}: IProps) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    company: Yup.string().required("Please enter your company name"),
    email: Yup.string()
      .email("This is not a valid email address")
      .required("Please enter your email address"),
  })

  const initialValues: IFormValues = {
    name: "",
    company: "",
    email: "",
    phone: "",
    challenge: "",
  }

  const handleSubmit = async (values: IFormValues) => {
    axios
      .post("/.netlify/functions/contact", {
        ...values,
      })
      .then(() => {
        setSuccess(true)
      })
      .catch(() => {
        setError(true)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <>
          {isSubmitting ? (
            <AnimationWrapper lOffset={4} l={8}>
              <LoadingAnimaton show={isSubmitting} />
            </AnimationWrapper>
          ) : (
            <>
              <Title>
                <AnimatedTextScrollWrapper
                  text={[title]}
                  textStyles={textStyles.headingM}
                />
              </Title>
              {text && text.html && (
                <AnimatedScrollWrapper>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: text.html,
                    }}
                  />
                </AnimatedScrollWrapper>
              )}
              <StyledForm>
                <FieldsWrapper>
                  <StyledTextField
                    name="name"
                    label="Full name"
                    error={touched.name && errors.name}
                  />
                  <StyledTextField
                    name="company"
                    label="Company"
                    error={touched.company && errors.company}
                  />
                  <StyledTextField
                    name="email"
                    label="Email address"
                    type="email"
                    error={touched.email && errors.email}
                  />
                  <StyledTextField
                    name="phone"
                    label="Phone number (optional)"
                    error={touched.phone && errors.phone}
                  />
                  <StyledTextField
                    name="challenge"
                    label="Your challenge or idea (optional)"
                    error={touched.challenge && errors.challenge}
                  />
                </FieldsWrapper>
                <Link
                  type="submit"
                  title={buttonLabel}
                  disabled={isSubmitting}
                />
              </StyledForm>
            </>
          )}
        </>
      )}
    </Formik>
  )
}

const AnimationWrapper = styled(Col)`
  padding-top: 80px;
  padding-bottom: 80px;

  ${mq.from.L`
    padding-top: 120px;
    padding-bottom: 120px;
  `}

  ${mq.from.XL`
    padding-top: 160px;
    padding-bottom: 160px;
  `}
`

const Title = styled.div`
  margin-bottom: 12px;

  ${mq.from.L`
    margin-bottom: 18px;
  `}

  ${mq.from.XL`
    margin-bottom: 24px;
  `}
`

const Text = styled.div`
  > p:last-child {
    margin-bottom: 0;
  }
`

const StyledForm = styled(Form)`
  margin-top: 48px;

  ${mq.from.M`
    margin-top: 64px;
  `}

  ${mq.from.XL`
    margin-top: 80px;
  `}
`

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.from.M`
    flex-direction: row;
    flex-wrap: wrap;
  `}
`

const StyledTextField = styled(TextField)`
  margin-bottom: 32px;

  ${mq.from.M`
    /* duplicate style because of IE */
    width: calc(50% - 3.125vw);
    width: calc(50% - (var(--base-column-size) / 2));

    :nth-child(odd) {
      /* duplicate style because of IE */
      margin-right: 6vw;
      margin-right: var(--base-column-size);
    }
  `}
`

export default TryUsForm
