import React from "react"
import styled, { css } from "styled-components"
import Image from "gatsby-background-image"

// Styling
import { mq } from "../styles/utils/mq"
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"

// Components
import { Row, Col } from "../components/layout/Grid"
import AnimatedScrollWrapper from "../components/animation/AnimatedScrollWrapper"
import AnimatedTextScrollWrapper from "../components/animation/AnimatedTextScrollWrapper"

// Types
import { IDiveIn } from "../types/prismic/TryUs/DiveIn"

const DiveIn = ({ title, text, image }: IDiveIn) => (
  <Container>
    <Col m={5} mOffset={1} l={6}>
      <AnimatedScrollWrapper>
        {image && <BackgroundImage fluid={image.url} alt={image.alt} />}
      </AnimatedScrollWrapper>
    </Col>

    <TextWrapper m={7} mOffset={1}>
      <TitleRow>
        {title && (
          <AnimatedTextScrollWrapper text={[title]} textStyles={titleStyle} />
        )}
      </TitleRow>

      <Row>
        {text && (
          <AnimatedScrollWrapper>
            <div dangerouslySetInnerHTML={{ __html: text.html }} />
          </AnimatedScrollWrapper>
        )}
      </Row>
    </TextWrapper>
  </Container>
)

const titleStyle = css`
  ${textStyles.headingM}

  ${mq.to.L`
    font-size: 32px;
    line-height: 1.12;
    letter-spacing: -0.67px;
  `}
`
const Container = styled(Row)`
  color: ${colors.black};
  width: 100%;
  overflow: hidden;

  /* to make padding top from layout disappear */
  margin-top: -12.5vw;
  margin-top: calc(-1 * var(--base-column-size));

  ${mq.from.M`
    align-items: center;
    margin-top: calc(-2 * var(--base-column-size));
  `}

  ${mq.from.XL`
    /* to make padding top from layout disappear */
    margin-top: -18.75vw;
    margin-top: calc(-3 * var(--base-column-size));
  `}
`

const BackgroundImage = styled(Image)`
  width: 100%;
  padding-top: 66.66666%;
  margin-bottom: 20px;

  ${mq.from.M`
    margin-bottom: 0;
    padding-top: 100%;
  `}
`

const TextWrapper = styled(Col)`
  ${textStyles.body}
  padding: 0 16px;

  p:last-child {
    margin-bottom: 0;
  }

  ${mq.from.M`
    padding: 0;
  `}
`

const TitleRow = styled(Row)`
  margin-bottom: 16px;

  ${mq.from.XL`
    margin-bottom: 18px;
  `}

  ${mq.from.XXL`
    margin-bottom: 30px;
  `}
`

export default DiveIn
